import React, { useRef, useState, useEffect } from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Footer from '../../_layout/Footer';
import FormStyles from './_styles';

const RECAPTCHA_SITE_KEY = "6LfjSC0qAAAAAPkmxunFEzhhCbMF-frgCtheyKie"; 
const RECAPTCHA_TOKEN = "RLwSKpWjdsGNRoKtbq86D6vV5vVAJJki4Jm71WBxjoy+Yyp6sOrYMvmqLv+P2Q/HRKCE5ITvE4BRvRab7Pgy7w==";

const Form = () => {
  const classes = FormStyles();
  const theme = useTheme();
  const buttonRef = useRef(null); 
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [typeOfHome, setTypeOfHome] = useState("");
  const [purchaseFor, setPurchaseFor] = useState("");
  const [hearAboutUs, setHearAboutUs] = useState("");
  const [isRelator, setIsRelator] = useState("");
  const [brokerage, setBrokerage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleLoaded = async () => {
    window?.grecaptcha?.ready(() => {
      window?.grecaptcha?.execute(RECAPTCHA_SITE_KEY, { action: "homepage" })
        .then(token => setRecaptchaToken(token))
    })
  }
  
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script)
  },[])
  
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <section className={`parallaxSection ${classes.formSection}`} id="form-section">
        <h1 className={classes.heading} data-aos="fade-up" data-aos-duration="1000">Register Today</h1>
        <form
          acceptCharset="UTF-8"
          id="spark-registration-form"
          className={classes.formContainer}
          action="https://spark.re/pilothouse/harth/register/website-reg-form"
          method="post"
        >
          <Grid container spacing={isSmall ? 0 : 6}>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <input className={classes.input} value={firstName} onChange={(e) => setFirstName(e.target.value)} id="contact_first_name" maxLength="50" name="contact[first_name]" placeholder="First Name*" required type="text" />
              <input className={classes.input} value={lastName} onChange={(e) => setLastName(e.target.value)} id="contact_last_name" maxLength="50" name="contact[last_name]" placeholder="Last Name*" required type="text" />
              <input className={classes.input} value={email} onChange={(e) => setEmail(e.target.value)} id="contact_email" maxLength="255" name="contact[email]" placeholder="Email*" required type="email" />
              <input className={classes.input} value={phone} onChange={(e) => setPhone(e.target.value)} id="contact_phone" maxLength="255" name="contact[phone]" placeholder="Phone*" required type="tel" />
              <input className={classes.input} value={zip} onChange={(e) => setZip(e.target.value)} id="contact_postcode" maxLength="255" name="contact[postcode]" placeholder="Postal/Zip" type="text" />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <select className={classes.select} value={typeOfHome} onChange={(e) => setTypeOfHome(e.target.value)}  name="answers[22516][answers]" id="answers_22516" required>
                <option value="">What type of home are you looking for?*</option>
                <option value="Studio">Studio</option>
                <option value="1 Bedroom">1 Bedroom</option>
                <option value="1 Bedroomr + Den ">1 Bedroomr + Den</option>
                <option value="Jr 2 Bedroom">Jr 2 Bedroom</option>
                <option value="2 Bedroom">2 Bedroom</option>
                <option value="2 Bedroom + Den">2 Bedroom + Den</option>
                <option value="Jr. 3 Bedroom">Jr. 3 Bedroom</option>
                <option value="3 Bedroom">3 Bedroom</option>
              </select>
              <select className={classes.select} value={purchaseFor} onChange={(e) => setPurchaseFor(e.target.value)}  name="answers[22517][answers]" id="answers_22517" required>
                <option value="">You are looking to purchase for?*</option>
                <option value="Investment">Investment</option>
                <option value="Yourself">Yourself</option>
              </select>
              <select className={classes.select} value={hearAboutUs} onChange={(e) => setHearAboutUs(e.target.value)} name="answers[22515][answers]" id="answers_22515"  required>
                <option value="">How did you hear about us?*</option>
                <option value="Social media">Social media</option> 
                <option value="Google">Google</option>
                <option value="Online">Online</option>
                <option value="Radio">Radio</option>
                <option value="Newspaper">Newspaper</option>
                <option value="Transit ad">Transit ad</option>
                <option value="Realtor">Realtor</option>
                <option value="Signage">Signage</option>
                <option value="Word of Mouth">Word of Mouth</option>
                <option value="REW">REW</option>
                <option value="Livabl">Livabl</option>
              </select>
              <select className={classes.select} value={isRelator} onChange={(e) => setIsRelator(e.target.value)} name="agent" id="agent">
                <option value="">Are you a realtor?</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
              {isRelator === "Yes" && <input className={classes.input} value={brokerage} onChange={(e) => setBrokerage(e.target.value)} id="answers_22518" maxLength="255" name="answers[22518][answers]" placeholder="If yes, which brokerage?" type="text" />}
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} xs={12} className={classes.checkBoxConatiner}>
            <p className={classes.required}>* Required Field</p>
            <FormGroup className={classes.checkboxGroup}>
              <FormControlLabel required control={<Checkbox />} label="By completing this contact for you confirm your consent to receive e-communications from Oviedo Developments and Pilothouse Project Marketing including information about this and upcoming developments, promotions, VIP early access or special offers. Should you wish to be excluded please click the appropriate link at the bottom of any email received to opt-out. To review our privacy policy, click here." />
            </FormGroup>
            <Grid container className={classes.buttonContainer}>
              <button type="submit" id="template-button" ref={buttonRef}>
                Submit <EastIcon/>
              </button>
            </Grid>
            <input type="hidden" name="authenticity_token" value={RECAPTCHA_TOKEN} autoComplete="off" />
            <input type="hidden" name="source" id="source" value="Website" autoComplete="off" />
            <input type="hidden" name="redirect_success" id="redirect_success" value="https://harth.corecreate.co/thankyou" autoComplete="off" />
            <input type="hidden" name="redirect_error" id="redirect_error" value="https://harth.corecreate.co/" autoComplete="off" />
            <input type="text" name="are_you_simulated" id="are_you_simulated" placeholder="Leave this field blank" style={{display: 'none' }}/>
            <input type="hidden" value={recaptchaToken} name="g-recaptcha-response" id="g-recaptcha-response" autoComplete="off" />
          </Grid>
        </form>
      <Footer />
    </section>
  );
};

export default Form;
