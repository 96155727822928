import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: `100%`,
    background: `#C3AD82`,
    color: `#000`,
    paddingTop: 100,
    paddingBottom: 50,
    display: `flex`,
    justifyContent: `center`,
    fontFamily: `Montserrat-Regular`,
    fontWeight: 500,
    
    '& a': {
      color: `#000`,
      fontFamily: `Montserrat-Regular`,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.33,
      textDecoration: `underline`
    },
  },
  logo: {
    display: `flex`,
    justifyContent: `center`,
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      flexWrap: `wrap`,
      marginBottom: 20
    },
    '& img': {
      margin: 15,
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        width: `100%`,
        display: `block`,
        margin: 0,
        marginBottom: 20
      },
    }
  },
  privacy:{
    marginBottom: 30,
    '& p': {
      fontFamily: `Montserrat-Regular`,
      marginRight: 12,
      [theme.breakpoints.down('md')]: {
        width: `60%`,
        display: `block`,
        textAlign: `center`
      },
    }
  },
  sentence: {
    fontFamily: `Montserrat-Regular`,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.33,
    maxWidth: `90%`,
    width: `100%`,
    textAlign: 'center',
    display: `block`
  },
  designedBy: {
    fontFamily: `Montserrat-Regular`,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.33,
    fontWeight: 500,
    marginTop: 30,
    maxWidth: `90%`,
    width: `100%`,
    textAlign: 'center',
    display: `block`
  },
}));

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer} id='footer'>
      <Grid container className={classes.logo}>
        <Grid container justifyContent="center" alignItems="center">
          <a href="https://oviedoproperties.com/" target="_blank"><img src="/img/logo/logo_ovideo.svg" alt="Ovideo Properties" /></a>
          <a href="https://pilothouseprojects.com/" target="_blank"><img src="/img/logo/logo_pilothouse.svg" alt="Pilothouse Project Marketing" /></a>
        </Grid>
          <Grid container justifyContent="center" alignItems="center" className={classes.privacy}>
            <p>@2024 Ovideo Developments</p>
            <p><a href="/privacy" target="_blank">Privacy Policy</a></p>
        </Grid>
        <Grid container justifyContent="center">
          <p className={classes.sentence}>This is not an offering for sale. Any such offerings may only be made with a Disclosure Statement. E.&O.E. Sales & Marketing by Pilothouse Project Marketing</p>
          <p className={classes.designedBy}>Designed by <a href="https://www.corecreate.co/" target="_blank">Core</a> </p>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
