// Auth components
// import LoginPage from 'core/pages/LoginPage';
// const Page404  import("pages/Page404"));
// const Page500  import("pages/Page500"));

// Admin Pages
import HomePage from 'core/pages/HomePage';
import ThankYouPage from 'core/pages/ThankYouPage';
import PrivacyPolicyPage from 'core/pages/PrivacyPolicyPage';
// import NotFound from '.core/pages/NotFound';

const HomeRoutes = {
  id: 'Home',
  path: '/',
  icon: null,
  component: HomePage,
  permissionId: [],
  children: null,
  showOnMenu: true,
};

const ThankyouRoutes = {
  id: 'thankyou',
  order: 2,
  name: 'thankyou-content',
  path: '/thankyou',
  component: ThankYouPage,
  permissions: [],
  icon: null,
  children: null,
  showOnMenu: false,
}

const PrivacyPolicyRoutes = {
  id: 'privacy',
  order: 2,
  name: 'thankyou-content',
  path: '/privacy',
  component: PrivacyPolicyPage,
  permissions: [],
  icon: null,
  children: null,
  showOnMenu: false,
}

// const Page404Routes = {
//   id: "Page404",
//   path: "/web/admin/404",
//   icon: <User />,
//   component: Page404,
//   children: null,
//   showOnMenu: false
// }

// const Page500Routes = {
//   id: "Page500",
//   path: "/web/admin/500",
//   icon: <User />,
//   component: Page500,
//   children: null,
//   showOnMenu: false
// };

// Routes using the TaskList layout
export const CoreRoutes = [HomeRoutes, ThankyouRoutes, PrivacyPolicyRoutes];
// export const AdminUserRoutes = [DashboardRoutes, AdminRoutes, PropertyRoutes, PasswordRoutes, SettingsRoutes];

// Routes using the Auth layout
export const AuthLayoutRoutes = [
  HomeRoutes,
  ThankyouRoutes,
  PrivacyPolicyRoutes
  //   Page404Routes,
  //   Page500Routes,
  //   changePasswordRoutes
];
