import { makeStyles } from "@mui/styles";

const FormStyles = makeStyles((theme) => ({
    formSection: {
        padding: 0,
        backgroundColor: `#173F35`,
        color: `#fff`,
        maxWidth: `100%`,
        width: `100%`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        flexWrap: `wrap`,
        margin: 0,
        '& #are_you_simulated': {
            display: `none`
        }
    },
    heading: {
        color: `#fff`,
        fontSize: 50,
        fontFamily: 'LibreCaslon-Regular',
        width: `100%`,
        textAlign: `center`,
        marginBottom: 80,
        marginTop: 50,
        [theme.breakpoints.down('md')]: {
            fontSize: 42,
            marginBottom: 50,
            marginTop: 50,
        },
    },
    formContainer: {
        width: `80%`,
        margin: `0 auto`,
        // border: `1px solid #ff0`,
        display: `flex`,
        flexWrap: `wrap`,
        [theme.breakpoints.down('md')]: {
            width: `90%`,
        },
        "& input[type='text'], & input[type='email'], & input[type='tel']": {
            padding: `0 !important`,
            fontFamily: 'Montserrat-Regular',
        }
    },
    input: {
        width: `100%`,
        background: `none !important`,
        outline: `none !important`,
        fontSize: `18px !important`,
        lineHeight: 1.333,
        fontFamily: 'Montserrat-Regular',
        border: `0px !important`,
        borderBottom: `1px solid #fff !important`,
        // padding: `14px !important`,
        marginBottom: 50,
        marginRight: 20,
        color: `#fff !important`,
        '&::placeholder': {
            color: `#fff !important`,
            marginLeft: `-15 !important`,
            fontFamily: 'Montserrat-Regular',
            // border: `10px solid red`
        },
        '& .MuiInputBase-root': {
            marginTop: 0
        },
        '& .MuiFormLabel-root': {
            fontSize: `18px !important`,
            lineHeight: 1.333,
            color: `#fff !important`,
            fontFamily: 'Montserrat-Regular',
            textTransform: 'capitalize',
        },
        '& input': {
            border: `0 !important`,
            outline: `none !important`,
            background: `none !important`,
            fontSize: `18px !important`,
            lineHeight: 1.333,
            color: `#fff !important`,
            fontFamily: 'Montserrat-Regular',
            paddingTop: `20px !important`,
        },
        '& .MuiInput-root::before, & .MuiInput-root::after': {
            borderBottom: `0px !important`
        },
        '& .MuiInput-root::after': {
            marginBottom: 50
        }
    },
    formController: {
        paddingBottom: `43px !important`,
        '& .MuiFormLabel-root': {
            fontSize: `18px !important`,
            lineHeight: 1.333,
            color: `#fff !important`,
            fontFamily: 'Montserrat-Regular',
            textTransform: 'capitalize',
            marginLeft: `-15px`
        },
        '& input': {
            border: `0px !important`,
            borderBottom: `1px solid #fff !important`,
            background: `none !important`,
            outline: `none !important`,
            width: `100%`,
            fontSize: `18px !important`,
            lineHeight: 1.333,
            color: `#fff !important`,
            fontFamily: 'Montserrat-Regular',
            '&::placeholder': {
                color: `#fff !important`,
                mpaddingarginLeft: `-10`
            },
        },
        '& fieldset': {
            display: `none`
        },
        '& .MuiInput-root::before, & .MuiInput-root::after': {
            borderBottom: `1px solid #fff !important`
        },
        '& .MuiSvgIcon-root fill': {
            path: '#fff !important',
        }
    },
    select: {
        width: `100%`,
        background: `none !important`,
        outline: `none !important`,
        fontSize: `18px !important`,
        lineHeight: 1.333,
        color: `#fff !important`,
        border: `0px !important`,
        borderBottom: `1px solid #fff !important`,
        fontFamily: 'Montserrat-Regular',
        textTransform: 'capitalize',
        padding: 10,
        paddingLeft: 0,
        marginBottom: 49,
        marginRight: 20,
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
        borderRadius: 0,
        '& input': {
            border: `0 !important`,
            outline: `none !important`,
            background: `none !important`,
            fontSize: `18px !important`,
            lineHeight: 1.333,
            color: `#fff !important`,
            fontFamily: 'Montserrat-Regular',
            paddingTop: `20px !important`,
        },
        '&::placeholder': {
            color: `#000 !important`,
            fontFamily: 'Montserrat-Regular',
            textTransform: 'capitalize',
        },
      
    },
    selectContainer: {
        // border: `2px solid red`,
        outline: `none !important`,
        height: 59,
        marginBottom: 49,
        '& div, & input': {
            outline: `none !important`,
            border: `0 !important`,
            boxShaow: `none !important`,
        },
        '& .react-select__control': {
            background: `none !important`,
            border: `0 !important`,
            borderBottom: `1px solid #fff !important`,
            borderRadius: `0 !important`,
            fontFamily: 'Montserrat-Regular',
            fontSize: `18px !important`,
            fontWeight: 500,
            lineHeight: 1.333,
            color: `#fff !important`,
            boxShaow: `0 !important`,
        },
        '& .react-select__value-container': {
            paddingBottom: 5,
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .react-select__placeholder': {
            color: `#fff !important`,
            outline: `none !important`,
        },
        "& :before": {
            borderBottom: "none !important",
        },
        "& :after": {
            borderBottom: "none !important",
        },
        '& .react-select__indicator-separator': {
            display: "none"
        },
        '& .react-select__indicator svg': {
            width: 30,
            height: 30
        },
        '& .react-select__single-value': {
            fontFamily: 'Montserrat-Regular',
            fontSize: `18px !important`,
            lineHeight: 1.333,
            color: `#fff !important`,
            marginLeft: 0
        },
        '& .react-select__menu': {

            backgroundColor: '#fff !important',
            padding: 8,
            fontFamily: 'Montserrat-Regular',
            fontSize: `18px !important`,
            lineHeight: 1.333,
            color: `#173F35 !important`,
        }
    },
    checkBoxConatiner: {
        paddingTop: 50
    },
    textField: {
      paddingBottom: 40
    },
    alert: {
      fontFamily: 'Montserrat-Regular',
      position: 'absolute',
      marginTop: '-40px',
      fontSize: 12
    },
    alertSelect: {
      fontFamily: 'Montserrat-Regular',
      position: 'absolute',
      marginTop: '-40px',
      fontSize: 12
    },
    required: {
      fontFamily: 'Montserrat-Regular',
      color: `#fff !important`,
      fontSize: 18,
      lineHeight: 1.1
    },
    checkboxGroup: {
        marginTop: 20,
        paddingLeft: 12,
        fontFamily: 'Montserrat-Regular',
        color: `#fff !important`,
        '& .MuiTypography-root': {
            fontFamily: 'Montserrat-Regular',
            color: `#fff !important`,
            fontSize: 12
        },
        '& .MuiFormControlLabel-root': {
            alignItems: 'flex-start'
        },
        '& .Mui-checked': {
            color: `#fff !important`
        },
        '& .MuiCheckbox-root': {
            paddingTop: 2
        },
        '& .MuiSvgIcon-root path': {
          fill: `#fff`
        }
    },
    buttonContainer: {
        marginTop: 50,
        marginBottom: 100,
        justifyContent: 'center',
        '& button': {
            width: 180,
            borderRadius: 50,
            border: `none`,
            padding: `15px 25px`,
            backgroundColor: `#fff`,
            color: `#173F35`,
            fontFamily: 'Montserrat-Regular',
            fontWeight: 500,
            fontSize: 14,
            textTransform: 'uppercase',
            display: `flex`,
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                marginLeft: 5,
                width: 20
            },
            '&:hover': {
                backgroundColor: `#869b83`,
                color: `#fff`,
            },
            '&::disabled': {
                cursor: 'auto',
                backgroundColor: `#869b83`,
                color: `#fff`,
            }
        }
    }
}));
  
export default FormStyles;