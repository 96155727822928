import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import HomeStyles from "./styles";

const useStyles = makeStyles((theme) => ({
  aboutSection: {
    backgroundImage: `url('/img/about/bg.jpg')`,
    backgroundColor: `#eae9da`,
    justifyContent: 'center',
    backgroundAttachment: `fixed`,
    backgroundPosition: `center`,
    backgroundSize: `cover`,
    padding: `150px 0`,
  },
  logo: {
    // border: `1px solid red`,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: `70%`,
    },
    '& svg fill': {
      path: '#C7613C'
    }
  },
  content: {
    display: `flex`,
    justifyContent: `center`,
    flexWrap: `wrap`,
    color: `#000`,
    '& p': {
      width: `100%`,
      padding: `0 35%`,
      fontSize: 24,
      lineHeight: 1.33,
      marginTop: 50,
      marginBottom: 50,
      fontFamily: 'Montserrat-Regular',
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        width: `70%`,
        padding: 0,
        fontSize: 20,
      },
    }
  },
  hrShort: {
    width: `28%`,
    display: `block`,
    margin: `0 auto`,
    marginTop: 60,
    border: 0,
    borderTop: `2px dashed #C7613C`,
    [theme.breakpoints.down('md')]: {
      width: `60%`,
    },
  },
  hrLong: {
    width: `40%`,
    margin: `0 auto`,
    marginTop: 20,
    border: 0,
    borderTop: `2px dashed #C7613C`,
    [theme.breakpoints.down('md')]: {
      width: `80%`,
    },
  },
}));


const About = () => {
  const classes = useStyles();
  const homeClass = HomeStyles();

  return (
    <section className={`parallaxSection ${homeClass.parallaxBg} ${classes.aboutSection} about-section`}>
      <Grid container item justifyContent="center">
        <Grid item className={classes.logo} data-aos='fade-up' data-aos-duration='1000'>
          <img
            src={`/img/logo/logo_orange_nocircle.svg`}
            alt='Heather Teaser'
          />
        </Grid>
        <Grid item className={classes.content}>
          <hr className={classes.hrShort}  data-aos='fade-up' data-aos-delay='50' data-aos-duration='1000'/>
          <p data-aos='fade-up' data-aos-delay='100' data-aos-duration='1000'>Find your place in this dynamic urban sanctuary. Harth is an innovative collection of Studio, 1, 2 & 3  bedroom homes that embodies all that is Newton.</p>
          <hr className={classes.hrLong} />
        </Grid>
      </Grid>
      
    </section>
  );
};

export default About;
