import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import HeaderStyles from 'core/pages/_layout/Header/_styles';

const ScrollToTop = () =>{
  const { t } = useTranslation() 
  const [isVisible, setIsVisible] = useState(false);
  const [isBtnVisible, setIsBtnVisible] = useState(false);
  const classes = HeaderStyles()
  const btnRef = useRef(null)

  const scrollToTop = () => {
    const section = document.querySelector(`#video-section`)
    const sectionHeight = section.offsetHeight;

    
    window.scrollTo({
      top: sectionHeight,
      behavior: "smooth",
    });

    // console.log(sectionHeight);
  };

  const scrollToForm = () => {
    const section = document.querySelector(`#form-section`)

    section.scrollIntoView({
      behavior: "smooth",
      block: 'start'
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const btnVisibility = () => {
      const footerHeight = document.getElementById("footer").offsetHeight;
      // console.log(footerHeight);
      // const detectBtnPos = document.body.offsetHeight - window.innerHeight - footerHeight*1.5;
      const detectBtnPos = document.body.offsetHeight - window.innerHeight - footerHeight*1.5
      // console.log(window.pageYOffset, document.body.offsetHeight - window.innerHeight - footerHeight );

      if (window.pageYOffset > window.innerHeight - 50 && window.pageYOffset < detectBtnPos ) {
        setIsBtnVisible(true);
      } else {
        setIsBtnVisible(false);
      }
    };

    window.addEventListener("scroll", btnVisibility);

    return () => window.removeEventListener("scroll", btnVisibility);
  }, []);

  return (
    <>
      {isBtnVisible && (<div className={`${classes.btnContainer} desktop`}>
        <div className={`${classes.topBarButton} desktop`} ref={btnRef} onClick={scrollToForm}>
          {t('commons.register')}
        </div>
      </div>)}

      {isVisible && (
        <div onClick={scrollToTop} className="scroll_up">
          <img src="/img/arrow-up.svg" alt="scroll up" />
        </div>
      )}
    </>
  );
}

export default ScrollToTop
